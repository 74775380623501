import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layout/subpage'

export default ({ data }) => {
  return(
    <Layout
      title={data.markdownRemark.frontmatter.title}
      description=""
      keywords=""
      content={data.markdownRemark.html}
    />
  )  
}

export const query = graphql`
  query PrivacyPolicyQuery {
    markdownRemark(frontmatter:{title:{eq: "Privacy Policy"}}) {
      frontmatter {
        title
      }
      html
    }
  }
`